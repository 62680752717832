import { UseQuery, useQuery } from 'shared/hooks/useQuery';
import { Product } from 'shared/constants';
import { Brand } from 'shared/types';

interface Response {
  code?: string;
  yearsModelsIntroduced: number[];
}

const useManufacturer = ({
  user,
  brand,
  product,
}: {
  user: Brand;
  brand: Brand;
  product: Product;
}): UseQuery<Response> => useQuery<Response>(true, `/api/manufacturers/${brand}`, product, user);

export { useManufacturer };
