import { Brand, Comparison } from 'shared/types';
import { fetcher } from 'shared/hooks/useQuery';
import isUndefined from 'lodash/isUndefined';
import { FormValues, VehicleComparison } from 'features/detailed-comparator/types';
import { Product } from 'shared/constants';

interface ReducedComparison {
  currentMileage: number | undefined;
  index: number;
  id: string;
  registrationYear: number;
}

interface GetVehicleBody extends FormValues {
  currentMileage?: number;
}

const getVehicle = async (
  vehicle: ReducedComparison,
  body: GetVehicleBody,
  brand: Brand,
  tool: Product
): Promise<{ index: number; data: Comparison[] }> => {
  const data: any = await fetcher(
    tool,
    brand,
    'POST',
    body
  )([`/api/vehicles/${vehicle.id}?brand=${brand}&dateOfFirstRegistration=${vehicle.registrationYear}`]);

  if (!isUndefined(data)) {
    return { index: vehicle.index, data: data.comparisons[0] };
  }

  throw new Error();
};

const useRecalculate = async (
  comparisons: VehicleComparison[],
  body: FormValues,
  brand: Brand,
  tool: Product
): Promise<{
  response: any | Array<{ index: number; data: Comparison[] }>;
  error: undefined | string;
}> => {
  let error;

  const vehicles = comparisons.reduce(
    (previousComparisons: ReducedComparison[], currentComparison: VehicleComparison, index: number) => {
      const { comparison, isComparisonSet } = currentComparison;
      if (isComparisonSet) {
        return [
          ...previousComparisons,
          {
            currentMileage: comparison.currentMileage,
            index,
            id: comparison.id,
            registrationYear: comparison.registrationYear,
          },
        ];
      }

      return previousComparisons;
    },
    []
  );

  const response = await Promise.all(
    vehicles.map(async (vehicle) =>
      getVehicle(vehicle, { ...body, currentMileage: vehicle.currentMileage }, brand, tool)
    )
  ).catch((e) => {
    error = e;
  });

  return { response, error };
};

export { useRecalculate, getVehicle };
