import { UseQuery, useQuery } from 'shared/hooks/useQuery';
import { Manufacturer } from 'features/detailed-comparator/types';
import { Product } from 'shared/constants';
import { Filter, filtersAsQueryString } from 'features/detailed-comparator/utils/getFiltersAsQueryString';
import { Brand } from 'shared/types';

interface Response {
  manufacturers?: Manufacturer[];
}

const useManufacturers = ({
  user,
  product,
  filters,
}: {
  user: Brand;
  product: Product;
  filters?: Filter[];
}): UseQuery<Response> => {
  const filterQueryString = filtersAsQueryString(filters);

  return useQuery<Response>(
    true,
    `/api/manufacturers/${filterQueryString ? `?${filterQueryString}` : ''}`,
    product,
    user
  );
};

export { useManufacturers };
