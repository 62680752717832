import { UseQuery, useQuery } from 'shared/hooks/useQuery';
import { Model } from 'features/detailed-comparator/types';
import { Product } from 'shared/constants';
import { Filter, filtersAsQueryString } from 'features/detailed-comparator/utils/getFiltersAsQueryString';
import { Brand } from 'shared/types';

interface Response {
  models?: Model[];
}

const useModels = ({
  manufacturerId,
  product,
  filters,
  registrationYear,
  user,
}: {
  manufacturerId: string;
  product: Product;
  filters?: Filter[];
  registrationYear?: string;
  user: Brand;
}): UseQuery<Response> => {
  const filterQueryString = filtersAsQueryString(filters);

  return useQuery<Response>(
    Boolean(manufacturerId),
    `/api/models/${manufacturerId}?${filterQueryString}${
      registrationYear ? `&registrationYear=${registrationYear}` : ''
    }`,
    product,
    user
  );
};

export { useModels };
