import React from 'react';
import { useTranslation } from 'next-i18next';
import { Product } from 'shared/constants';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { useBrand } from 'shared/hooks/useBrand';
import { useTheme } from 'shared/hooks/useTheme';
import { formatToCurrency } from 'shared/utils/currency';
import { ButtonSizes, SecondaryButton, TertiaryButton } from 'shared/components/Button';
import { changeVehicle } from 'features/detailed-comparator/components/VehicleSelection/constants';
import { FeatureToggles } from 'shared/constants/features';
import { Comparison, FuelType, SyntheticTestTypes } from 'shared/types';
import { useVehicleTestTypeModal } from 'shared/hooks/useVehicleTestTypeModal';
import { useBrandConfig } from 'shared/hooks/useBrandConfig';
import { useFeature } from 'shared/hooks/useFeature';
import { usePlausible } from 'next-plausible';
import styled, { css } from 'styled-components';
import { makeVehicleImageUrl } from 'shared/utils/makeVehicleImageUrl';
import { transformBrandCasing } from 'shared/utils/tranformBrandCasing';

interface Props {
  comparison: Comparison;
  position: number;
  onClick: (position: number, isComparisonSet: boolean, isVrmComparison: boolean) => void;
  onClearComparison: (position: number) => void;
  isComparisonSet: boolean;
  isVrmComparison: boolean;
  product: Product;
  isSticky: boolean;
  registrationYear?: number;
  isStickySelectorView?: boolean;
}

const ShopButton = styled(SecondaryButton)`
  ${({ theme }) => `
    margin: 14px 0 0;
    margin: ${theme.components.vehicleSummary.shopButton.margin.mobile};
    order: 5;

    @media (min-width: ${theme.viewports.desktop}) {
      margin: ${theme.components.vehicleSummary.shopButton.margin.desktop};
    }
  `}
`;

const VehicleImage = styled.img<{ isSticky: boolean }>`
  ${({ isSticky, theme }) => {
    const imageStyles = theme.components.vehicleOption.image;
    return `
    display: ${isSticky ? 'none' : 'initial'};
    width: ${imageStyles.mobile.width};
    height: ${imageStyles.mobile.height};

    @media (min-width: ${theme.viewports.tablet}) {
      display: initial;
      order: 4;
      width: ${isSticky ? imageStyles.mobile.stickyWidth : imageStyles.width};
      height: ${isSticky ? imageStyles.mobile.stickyHeight : imageStyles.height};
      max-height: 190px;
      align-self: ${isSticky ? 'flex-start' : 'center'};
      object-fit: contain;
    }
  `;
  }}
`;

const ChangeAndCloseButtonWrapper = styled.div<{ isSticky: boolean }>`
  ${({ isSticky, theme }) => `
    display: ${isSticky ? 'none' : 'flex'};
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: ${theme.components.vehicleSummary.changeButton.margin.mobile};

    @media (min-width: ${theme.viewports.desktop}) {
      margin: ${theme.components.vehicleSummary.changeButton.margin.desktop};
    }
  `}
`;

const ChangeButton = styled(TertiaryButton)`
  &&& {
    justify-content: flex-start;
    min-width: unset;
    height: unset;
  }
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  height: 100%;
`;

const CloseButtonIcon = styled.svg<{ src: string }>`
  ${({ theme, src }) => `
    width: ${theme.components.vehicleSummary.width};
    min-width: ${theme.components.vehicleSummary.width};
    height: ${theme.components.vehicleSummary.width};
    background-color: ${theme.components.vehicleSummary.closeButton.icon.backgroundColor};
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(${src});
  `}
`;

const VehicleTitle = styled.h3`
  ${({ theme }) => `
    align-self: flex-start;
    font-size: ${theme.fontSizes.sm};
    margin-right: ${theme.components.vehicleSummary.width};
    text-transform: capitalize;
    font: ${theme.components.vehicleSummary.title.font.mobile};
    margin: ${theme.components.vehicleSummary.title.margin.mobile};

    @media (min-width: ${theme.viewports.tablet}) {
      font-size: ${theme.fontSizes.lg};
      font: ${theme.components.vehicleSummary.title.font.desktop};
      margin: ${theme.components.vehicleSummary.title.margin.desktop};
    }
  `}
`;

const descriptionAndDetailsStyles = css`
  ${({ theme }) => `
    align-self: ${theme.components.vehicleOption.vehicleSummaryDetails.alignSelf};
    display: ${theme.components.vehicleOption.vehicleSummaryDetails.display};
    font-size: ${theme.fontSizes.md};
    color: ${theme.components.vehicleOption.vehicleSummaryDetails.color};
    font: ${theme.components.vehicleOption.vehicleSummaryDetails.font.mobile};

    @media (min-width: ${theme.viewports.tablet}) {
      display: ${theme.components.vehicleOption.vehicleSummaryDetails.display};
    };
  `}
`;

const VehicleDescription = styled.p`
  ${descriptionAndDetailsStyles};
  margin: ${({ theme }) => theme.components.vehicleSummary.description.margin.mobile};

  @media (min-width: ${({ theme }) => theme.viewports.tablet}) {
    margin: ${({ theme }) => theme.components.vehicleSummary.description.margin.desktop};
  }
`;

const VehicleDetails = styled.div<{ isSticky: boolean }>`
  ${descriptionAndDetailsStyles};
  display: ${({ isSticky }) => (isSticky ? 'none' : 'initial')};

  @media (min-width: ${({ theme }) => theme.viewports.tablet}) {
    justify-content: space-between;
    width: 100%;
  }
`;

const TestStandardWrapper = styled.div<{ isSticky: boolean }>`
  ${({ isSticky, theme }) => `
    display: ${isSticky ? 'none' : 'initial'};
    order: ${theme.components.vehicleOption.testStandard.wrapper.order};
    width: ${theme.components.vehicleOption.testStandard.wrapper.width};
    color: ${theme.components.vehicleOption.testStandard.wrapper.color};
    border-top: 1px solid ${theme.components.vehicleOption.hr.color};
    padding: ${theme.components.vehicleOption.testStandard.wrapper.padding};
  `}
`;

const TestStandard = styled.p`
  font: ${({ theme }) => theme.components.vehicleOption.testStandard.font};
`;

const TestStandardTypeButton = styled.button`
  background-color: unset;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  padding: unset;
`;

const TestStandardType = styled.span`
  font: ${({ theme }) => theme.components.vehicleOption.testStandard.type.font};
`;

const SuperScript = styled.sup`
  font: ${({ theme }) => theme.components.vehicleOption.superscript.font};
`;

const VehicleSummary: React.FC<Props> = ({
  comparison,
  position,
  onClick,
  onClearComparison,
  product,
  isSticky,
  isComparisonSet,
  isVrmComparison,
  isStickySelectorView,
}) => {
  const theme = useTheme();
  const brand = useBrand();
  const brandConfig = useBrandConfig()[product];
  const plausible = usePlausible();

  const {
    id,
    bodyStyle,
    description,
    fuel,
    transmission,
    totalPrice,
    model,
    shortModel,
    testType,
    manufacturer,
    registrationYear,
  } = comparison;

  const { openModal } = useVehicleTestTypeModal();

  const { t: translate } = useTranslation(`${brand}.${product}`);

  const { logEvent } = useAnalytics();

  const { earliestRegistrationYearPermitted, features, hasEVTestType, hasPhevTestType } = brandConfig || {};
  const type =
    hasPhevTestType && fuel === FuelType.PLUG_IN_HYBRID
      ? SyntheticTestTypes.PHEV
      : (hasEVTestType && fuel === FuelType.ELECTRIC && SyntheticTestTypes.EV) || testType;
  const { isImageResizeOnStickyDisabled, shouldHideOnTheRoadPrice } = features?.vehicleSummary || {};
  const { isShopCTAShown, makeShopLink } = features?.shopLinkCTA || {};
  const shouldShowRegistrationYear = Boolean(earliestRegistrationYearPermitted);
  const shouldShowTestStandard = useFeature(FeatureToggles.FEATURE_DETAILED_COMPARATOR_SHOW_TEST_STANDARD, brand);
  const shouldShowTestStandardModal = useFeature(FeatureToggles.FEATURE_SHOW_TEST_TYPE_MODAL, brand);

  const handleClick = async (): Promise<void> => {
    await logEvent(`/change-vehicle-form/open/${position + 1}`);

    onClick(position, isComparisonSet, isVrmComparison);
  };

  const handleClearComparison = (): void => {
    onClearComparison(position);
  };

  const changeVehicleConfiguration = changeVehicle[brand];
  const shopLink =
    isShopCTAShown &&
    makeShopLink({
      manufacturer,
      model,
      fuelType: fuel,
    });
  const isSuperscriptVisible = manufacturer.toLowerCase() === brand;
  const manufacturerNameWithCorrectCasing = transformBrandCasing(manufacturer);

  return (
    <div className="vehicle-summary" data-test={`vehicle-summary-${position}`}>
      <ChangeAndCloseButtonWrapper isSticky={Boolean(isSticky && isStickySelectorView)}>
        {isComparisonSet && (
          <ChangeButton
            text={translate('vehicle-summary.change-car')}
            onClick={handleClick}
            data-test="vehicle-summary-change-car"
            iconSource={
              changeVehicleConfiguration.icon.show ? `/assets/${brand}/${changeVehicleConfiguration.icon.src}` : ''
            }
            appendIcon={changeVehicleConfiguration.icon.append}
            buttonSize={ButtonSizes.SMALL}
          />
        )}

        <CloseButton type="button" data-test="vehicle-summary-close" onClick={handleClearComparison}>
          <CloseButtonIcon aria-label="Remove vehicle" role="presentation" src={theme.components.vehicleSummary.icon} />
        </CloseButton>
      </ChangeAndCloseButtonWrapper>

      <VehicleTitle data-test={`vehicle-summary-model-${position}`}>
        {manufacturerNameWithCorrectCasing} {shortModel} {description}
      </VehicleTitle>
      <VehicleDescription data-test={`vehicle-summary-description-${position}`}>
        {`${
          shouldShowRegistrationYear && Boolean(registrationYear) ? `${registrationYear} | ` : ''
        }${fuel} | ${transmission} | ${bodyStyle}`}
      </VehicleDescription>
      <VehicleDetails isSticky={Boolean(isSticky && isStickySelectorView && !isImageResizeOnStickyDisabled)}>
        {shouldHideOnTheRoadPrice ? null : (
          <p className="vehicle-details--price" data-test={`vehicle-details-price-${position}`}>
            {translate('vehicle-summary.starting-from')} <span className="price">{formatToCurrency(totalPrice)}</span>
            {isSuperscriptVisible ? (
              <SuperScript>{translate('vehicle-summary.starting-from-superscript')}</SuperScript>
            ) : null}
          </p>
        )}
      </VehicleDetails>
      <VehicleImage
        alt={description}
        src={makeVehicleImageUrl(brand, id, manufacturer)}
        isSticky={Boolean(isSticky && isStickySelectorView && !isImageResizeOnStickyDisabled)}
      />
      {shopLink ? (
        <ShopButton
          href={shopLink}
          isLink
          target="_blank"
          rel="noopener noreferrer"
          text={translate('vehicle-summary.shop')}
          buttonSize={ButtonSizes.MEDIUM}
          data-test="shop-cta"
          onClick={() => {
            plausible('shop_cta_clicked', {
              props: {
                product,
              },
            });
          }}
        />
      ) : null}
      {shouldShowTestStandard && (
        <TestStandardWrapper isSticky={Boolean(isSticky && isStickySelectorView && !isImageResizeOnStickyDisabled)}>
          {shouldShowTestStandardModal ? (
            <TestStandard>
              Test standard:{' '}
              <TestStandardTypeButton
                type="button"
                data-test={`vehicle-summary-test-standard-link-${position}`}
                onClick={() => {
                  plausible('test_type_standard_clicked', {
                    props: {
                      product,
                      testType: type,
                    },
                  });
                  openModal(type);
                }}
              >
                <TestStandardType>{type}</TestStandardType>
              </TestStandardTypeButton>
            </TestStandard>
          ) : (
            <TestStandard data-test={`vehicle-summary-test-standard-link-${position}`}>
              Test standard: <TestStandardType>{type}</TestStandardType>
            </TestStandard>
          )}
        </TestStandardWrapper>
      )}
      <style jsx>
        {`
          .vehicle-summary {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            align-items: flex-start;
            min-height: ${isSticky ? 'unset' : '85px'};
            min-width: 85px;
            width: ${theme.components.vehicleOption.width.mobile};
            position: relative;
          }

          .vehicle-summary:nth-child(3) {
            display: none;
          }

          .vehicle-summary:first-of-type {
            margin-left: 0;
          }

          .vehicle-summary:last-of-type {
            margin-right: 0;
          }

          .price {
            font-family: ${theme.components.vehicleOption.price.fontFamily};
            font-weight: ${theme.components.vehicleOption.price.fontWeight};
          }

          @media (min-width: ${theme.viewports.tablet}) {
            .vehicle-summary {
              align-items: flex-start;
              justify-content: flex-start;
              padding: 0 ${theme.spacing.md};
              width: ${theme.components.vehicleOption.width.desktop};
            }

            .vehicle-summary:nth-child(3) {
              display: flex;
            }
          }

          @media (min-width: 1240px) {
            .vehicle-summary {
              min-width: 308px;
              min-height: ${isSticky ? 'unset' : '230px'};
            }
          }
        `}
      </style>
    </div>
  );
};

export default VehicleSummary;
