import { UseQuery, useQuery } from 'shared/hooks/useQuery';
import { Brand, Comparison } from 'shared/types';
import { Product } from 'shared/constants';

interface ComparisonResponse {
  comparisons: Comparison[];
}

const useComparison = (
  vehicleId: string | [string, string],
  brand: Brand,
  tool: Product,
  method: 'GET' | 'POST' = 'GET',
  body?: any
): UseQuery<ComparisonResponse> => {
  const isVehicleIdArray = Array.isArray(vehicleId);
  const id = isVehicleIdArray ? vehicleId[0] : vehicleId;
  const dateOfFirstRegistration = (isVehicleIdArray && vehicleId[1]) || new Date().getFullYear();
  const fetchUrl = `/api/vehicles/${id}?brand=${brand}&dateOfFirstRegistration=${dateOfFirstRegistration}`;

  return useQuery<ComparisonResponse>(Boolean(id), fetchUrl, tool, brand, method, body ?? null);
};

export { useComparison };
